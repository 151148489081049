import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"

const Posts = ({ data }) => {
  const posts = data.allContentfulPost.edges;
  return (
    <Layout>
      <SEO title="Posts" />
      <h1>{"Here's a list of all posts!"}</h1>
      <div className="posts">
        {posts.map(({ node: post }) => (
          <div key={post.id}>
            <Link to={`/post/${post.slug}`}>{post.title}</Link>
            <img alt={''} src={post.image.file.url} />
          </div>
        ))}
        <span className="" />
        <Link to="/">Go back to the homepage</Link>
      </div>
    </Layout>
  )
}

export default Posts;

export const query = graphql`
  query PostsPageQuery {
    allContentfulPost(limit: 1000) {
      edges {
        node {
          id
          title
          slug
          content {
            childContentfulRichText {
              html
            }
          }
          image {
            file {
              url
            }
          }
          
        }
      }
    }
  }
`;
